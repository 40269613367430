.Location {
  padding: 6rem 0;
  width: 100%;
  background-color: #f1f1f1;
}


.location-banner img {
  width: 100%;
  object-fit: cover;
  height: 40vh;
}

.Page-title h3 {
  text-align: center;
  color: #212121;
  padding-bottom: 10px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 2rem;
}

.location-box iframe {
  width: 100%;
  height: 70vh;
}

@media only screen and (max-width: 680px) {
  .Page-title h1 {
    text-align: center;
    color: #212121;
    font-size: 25px;
    padding-bottom: 10px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
}
