.SiteVisit {
  padding: 9rem 0 3rem 0;
  width: 100%;
  background-color: #f1f1f1;
}

.SiteVisit-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sitevisit-box{
    display: flex;
    align-items: center;
    justify-content: center;
}

.sitevisit-item{
    width: 50%;
}

@media (min-width: 1200px) {
  .SiteVisit-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {
    .sitevisit-item{
        width: 100%;
    }
    .sitevisit-box{
      padding: 5rem 1rem;
  }
}
