.Gallery {
  padding: 3rem 0;
  width: 100%;
  margin-top: 80px;
  background-color: #f1f1f1;
}

.Gallery-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.Page-title h1{
  text-align: center;
  margin-bottom: 2rem;
}

.gallery-box {
  display: flex;
  gap: 1rem;
}

.gallery-items1 {
  width: 60%;
}
.gallery-items2 {
  width: 40%;
}

.gallery-box img {
  width: 100%;
  border-radius: 8px;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.gallery-box2 {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.gallery-box2 img {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
}

.Gallery-main .model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000d3;
  transition: opacity 0.4s ease, visibility 0.4s, transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}
.Gallery-main .model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  z-index: 9999;
}
.Gallery-main .model.open img {
  width: auto;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0px 20px;
  margin: 0 auto;
}
.Gallery-main .model.open svg {
 position: fixed;
 top: 4%;
 right: 4%;
 font-size: 40px;
 cursor: pointer;
 background-color: red;
 border-radius: 8px;
 color: white;
 z-index: 99;
}

.model .owl-nav button {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px !important;
}
.model .owl-nav button span{
 color: white;
 font-size: 200px;
}
.model .owl-nav button.owl-prev {
  position: absolute;
  top: 45%;
  left: 1%;
}
.model .owl-nav button.owl-next {
  position: absolute;
  top: 45%;
  right: 1%;
}

@media (min-width: 1200px) {
  .Gallery-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {

  .gallery-box{
    flex-wrap: wrap;
  }

  .gallery-items1 {
    width: 100%;
}

.gallery-items2 {
  width: 100%;
}

  .gallery-box2 {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .Gallery-main .model.open img {
    object-fit: contain;
  }
}
