.Walkthrough {
  padding: 6rem 0rem;
  width: 100%;
  background-color: black;
}

.Walkthrough-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.Walkthrough-main h2{
  font-size: 30px;
  color: white;
  text-align: center;
  margin-bottom: 2rem;
}

.walkthrough-item {
  display: flex;
  gap: 1rem;
}

.walkthrough-left {
  width: 70%;
}
.walkthrough-left iframe {
  width: 100%;
  height: 100%;
}
.walkthrough-right {
  width: 30%;
}

.walkthrough-form {
  padding: 2rem;
  border-radius: 8px;
  background-color: #fafafa;
}
.walkthrough-form h4 {
  font-size: 18px;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .Walkthrough-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {
    .walkthrough-item {
      flex-wrap: wrap;
    }
  
    .walkthrough-left,
    .walkthrough-right {
      width: 100%;
    }
  
    .category-card {
      height: 200px;
    }
  }
