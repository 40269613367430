.HGallery {
  padding: 6rem 0rem;
  width: 100%;
}

.HGallery-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.HGallery-main h3 {
  text-align: center;
  margin-bottom: 2rem;
}

.hgallery-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.hgallery-box img {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
}

.hgallery-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.hgallery-button a {
  padding: 10px 20px;
  background: #b27c34;
  display: flex;
  place-content: center;
  place-items: center;
  border-radius: 8px;
  color: white;
  transition: 0.2s ease-in-out;
  text-decoration: none;
}

.hgallery-button a:hover {
    background-color: #ffb000;
    color: black;
  }

@media (min-width: 1200px) {
  .HGallery-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {
    .hgallery-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
      }
  }
