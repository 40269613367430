/* .banner-image:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  left: 0;
  display: inline-block;
  opacity: 0.9;
  background-image: linear-gradient(0deg, #000 0%, rgba(255, 255, 255, 0) 100%);
} */

.banner img {
  height: 90vh;
  width: 100%;
  object-fit: cover;
  filter: brightness(70%);
}
.banner {
  margin-top: 75px;
  position: relative;
}

.stickey img {
  width: 100%;
  object-fit: cover;
}

.banner-stick{
  position: absolute;
  bottom: 5%;
  left: 5%;
  z-index: 1;
}

.banner-stick-box{
  background-color: rgba(0, 0, 0, 0.466);
  color: white;
  padding: 2rem 3rem;
  border-radius: 8px;
  /* text-align: center; */
}

.banner-stick-box h2{
  /* margin-bottom: 1rem; */
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  color: #e6b16a;
}
.banner-stick-box h5{
  margin: 10px 0px;
  font-weight: 300;
}
.banner-stick-box p{
  margin-bottom: 10px;
  font-size: 18px;
}
.banner-stick-box p span{
  font-size: 35px;
  font-weight: 500;
  color: #e6b16a;
}

@media only screen and (max-width: 680px) {
  .banner img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
}

@media only screen and (max-width: 680px) {
  .banner-stick{
    background-color: black;
    position: relative;
    left: 0;
    z-index: 1;
  }

  .banner-stick-box{
    padding: 1rem ;
  }
}