.Highlights {
  width: 100%;
}

.highlights-item {
  display: flex;
  gap: 2rem;
}

.highlights-left {
  width: 50%;
  padding: 3rem 1rem ;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.highlights-left h2{
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.highlights-left ul li{
  display: flex;
  padding-bottom: 20px;
  align-items: center;
}
.highlights-left ul li p{
  margin: 0;
}
.highlights-left ul{
  padding: 0;
}
.highlights-left ul li svg{
  margin-right: 10px;
  color: #b27c34;
  font-size: 20px;
}


.highlights-right {
  width: 50%;
}
.highlights-right img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

@media (min-width: 1200px) {
}

@media only screen and (max-width: 680px) {
  .highlights-item {
    flex-wrap: wrap;
  }
  .highlights-left {
    width: 100%;
    padding: 3rem 1rem 1rem 1rem;
  }
  .highlights-right {
    width: 100%;
  }
}
